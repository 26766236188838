// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCVqsuzHzCKNVfgqY6nelNRQiNSkrgJtU",
  authDomain: "food-app-6b183.firebaseapp.com",
  projectId: "food-app-6b183",
  storageBucket: "food-app-6b183.appspot.com",
  messagingSenderId: "160075875348",
  appId: "1:160075875348:web:fdc60408b240df0ea8d301",
  measurementId: "G-ZX15P8C2GB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const storage = getStorage(app);
// export default storage;
export const db = getFirestore(app);
export const storage = getStorage(app);

